import React, {useState, useReducer, useEffect} from 'react';

import LoginForm from "./LoginForm"
import './login.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import axios from 'axios';
import MediaQuery from 'react-responsive'

import jwt_decode from "jwt-decode";
const sign = require('jwt-encode');

const secret = 'secret';
const placesImg = require("./imgs/places.png")
const phTemplates = require("./imgs/phtemplates.png")
const guidesImg = require("./imgs/guides.png")
const checklistImg = require("./imgs/checklist.png")
const tools = require("./imgs/tools.png")
const ex1 = require("./imgs/ex1.png")
const logoImg = require("./imgs/logo.png")
const newsletter = require("./imgs/newsletter.png")
const replies = require("./imgs/replies.png")
const notioncrm = require("./imgs/notioncrm.png")
const chatgpt = require("./imgs/chatgpt.png")

function LoginPage(props: any) {
  const [loginForm, showLoginForm] = useState(false)
  const [display, setDisplay] = useState([])
  const [email, setEmail] = useState("")
  const [userLogged, setUserLogged] = useState(false)
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [prime, setPrime] = useState(false)
  async function loggedIn(status: boolean, email: string){
      if (status == true)
      {
        reactLocalStorage.setObject('d', sign({'e': email}, secret));
        window.location.reload();
         }
  }

  

   function checkEmail(e: any) {
       let modEmail: string = email.replace("@", "%40")
       window.open("https://store.payproglobal.com/checkout?products[1][id]=75462&billing-email="+modEmail, '_blank')!.focus();
   }

    
return (
    <div className="login-container">
    <div className="login-header">
  
    <div className="login-navbar">
      <div className="login-logo"><img alt="" width="70px" height="60px" src={logoImg}/></div>
    </div>

    <div className="login-description">
      <div className="ld1">Hack <span style={{color: "#d1b513"}}>Your</span> Growth</div>
      <div className="ld2">Checklists, Guides, Places, Templates and Tools for Startup Growth in Notion</div>
    </div>
    <a href="https://www.producthunt.com/posts/growth-hacking-kit?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-growth&#0045;hacking&#0045;kit" target="_blank">
    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=347685&theme=light&period=daily" alt="Growth&#0032;Hacking&#0032;Kit - Checklists&#0044;&#0032;guides&#0044;&#0032;places&#0044;&#0032;and&#0032;tools&#0032;for&#0032;startup&#0032;growth | Product Hunt" style={{width:"250px", height:"54px", marginTop:"20px"}} /></a>
    </div>

    <div className="login-main" id="login-main-block">
    <div className="login-block">
      <div className="login-h">100+ Free Places Where You Can Post Your Startup</div>
      <img className="login-img img-type-one" style={{width: "50rem"}} src={placesImg}/>
      <div className="login-main-descr">More then 100 places to post your startup for free. Dedicated sites, blogs, reviews to help you grow your audince by a lot
      <br/>
      <br/>
      Also we have 35 communities for founders where you can connect with likeminded people, find cofounders or raise an investment round!
      </div>
    </div>

    
    <div className="login-block" style={{backgroundColor: "#bd2241", color: "white", width: "100%"}}>
      <div className="login-h">100 Product Hunt Maker Comment Templates</div>
      <img className="login-img img-type-two" style={{width: "45rem"}} src={phTemplates}/>
      <div className="login-main-descr">Maker comment is like a pitch, it gives you opportunity to present your product to PH community. 

     Just choose template, insert your information in it and get to the top!
      <br/><br/>You will receive 25 Cold Email and LinkedIn (Connection request) templates as well.

      </div>
    </div>

     <div className="login-block" style={{backgroundColor: "black", color: "white", width: "100%"}}>
      <div className="login-h">Notion Startup CRM</div>
       <img className="login-img img-type-one" style={{width: "50rem"}} src={notioncrm}/>
            
      <div className="login-main-descr"> 
      Notion templates, designed specifically to address your business needs across five critical categories: Promotion and Selling, Managing Finances, Managing Team, Organizing Work and Planning. 
     
      </div>
    </div>

    <div className="login-block" style={{background: "#bd2241", color: "white", width: "100%"}}>
      <div className="login-h">ChatGPT Marketing Prompts</div>
      <img className="login-img img-type-one" style={{width: "50rem"}} src={chatgpt}/>
              
      <div className="login-main-descr">
      70 Prompts to help you grow your business with ChatGPT
      </div>
    </div>

    <div className="login-block">
      <div className="login-h">Newsletter Sponsorship Resources</div>
      <img className="login-img img-type-one" style={{width: "50rem"}} src={newsletter}/>
      <div className="login-main-descr">Explore 35 newsletters ready to promote your product.

    
      </div>
    </div>


    <div className="login-block" style={{backgroundColor: "#bd2241", color: "white", width: "100%"}}>
      <div className="login-h">50 Ways to Say Thank You To Your Supporters</div>
      <img className="login-img img-type-two" style={{width: "45rem"}} src={replies}/>
      <div className="login-main-descr">"Thank you" on every comment looks monotonously so we made 50 different templates (regarding product launch) for thanking your supporters.

      </div>
    </div>
    <div className="login-block" style={{backgroundColor: "black", color: "white", width: "100%"}}>
      <div className="login-h">70+ Guides</div>
      <img className="login-img img-type-one" style={{width: "50rem"}} src={guidesImg}/>
      <div className="login-main-descr">Get new insights of launching and growing a project! 

      </div>
    </div>
<div className="login-block" style={{backgroundColor: "#bd2241", color: "white", width: "100%"}}>
      <div className="login-h">Checklists and Tips</div>
      <img className="login-img img-type-one" style={{width: "40rem"}} src={ex1}/>
      <div className="login-main-descr">In addition to Guides you will receive growth checklists and tips for categories listed in Guides

      </div>
    </div>
    <div className="login-block" style={{backgroundColor: "black", color: "white", width: "100%"}}>
      <div className="login-h">Growth Tools</div>
      <img className="login-img img-type-three" style={{width: "20rem"}} src={tools}/>
      <div className="login-main-descr">
      More than best 100 tools in 10 categories will help you grow and manage you startup effectively.
      </div>
    </div>
    <div className="login-block" style={{backgroundColor: "#bd2241", color: "white", width: "100%"}}>
      <div className="login-h">Don't know where to start? It's not a problem! We have launch checklists specially for you!</div>
      <img className="login-img img-type-one" style={{width: "50rem"}} src={checklistImg}/>

    </div>
  <div className="login-block">

    <div className="login-sell"><div className="login-sell-message" style={{fontSize: "40px"}}>Get <span style={{color: "#d1b513"}}>Growth Hacking Kit</span> by early price!</div>

    <div className="login-offer" id="buy-offer">
    <div style={{fontSize: "45px"}}>Early price</div>
      <div style={{fontSize: "40px", marginTop: "15px"}}>29$ <span style={{textDecoration: "line-through"}}>59$</span></div>
      <div className="login-features-list">
      <span>  &#x02713; 100+ Places to Post your Startup</span>
       <span>   &#x02713; 35 Newsletter Sponsorships</span>
        <span>   &#x02713; 35 Communities for Founders</span>
        <span>   &#x02713; 100 Product Hunt First Comment Templates</span>
        <span>   &#x02713; 25 Cold Email and LinkedIn Templates</span>
        <span>   &#x02713; 100+ Tools</span>
        <span>   &#x02713; 70+ Growth Guides, Checklists and Tips</span>
        <span>   &#x02713; Bonus: Fundraising Checklist and Databases</span>

      </div>
      <input type="text" onChange={(e: any)=>{setEmail(e.target.value)}} className="login-email" placeholder={"Email"}/>
      <div onClick={checkEmail} className="buy-button">Buy Lifetime</div>


      <div>Secure via PayPro Global</div>

    </div>
    </div>
    </div>
    </div>
    <div className="login-footer">
      © 2024 Max Rush. If you didn't receive the kit please check spam folder. You can contact us via email max@hackgrowth.tech or via Twitter <a style={{color: "white", textDecoration:"none"}} href="https://twitter.com/realmaxrush">@realmaxrush</a>
    </div>
    </div>
  );
}

export default LoginPage;
