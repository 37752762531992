import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from './App';
import Promote from './Promote/Promote';
import Grow from './Grow/Grow';
import Places from './Promote/Places';
import Coms from './Promote/Coms';
import Templates from './Templates/Templates';
import LoginPage from "./LoginPage"
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <BrowserRouter> 
     <Routes>
      <Route path="/" element={<LoginPage />}/>
    </Routes>
     </BrowserRouter>
   
  </React.StrictMode>
);
